import { useNotification } from '@/context/Notification';
import { List, ListItem } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import CustomNotification from './CustomNotification';

const NotificationList = () => {
  const { pathname } = useRouter();
  const { notifToShow, closeNotification } = useNotification();

  const notificationContainerRef = useRef(null);

  // Set a padding top to the body depending the notification bar height
  useEffect(() => {
    const bodyElement = document.querySelector<HTMLElement>('body');
    if (notificationContainerRef.current && bodyElement) {
      // @ts-ignore
      bodyElement.style.paddingTop =
        notificationContainerRef.current['clientHeight'] + 'px';
    }
  }, [notifToShow]);

  return (
    <List
      ref={notificationContainerRef}
      width="100%"
      position="fixed"
      top="0"
      zIndex="modal"
      margin="0 !important"
      className="general-notification-list"
      data-testid="general-notification-list"
    >
      {notifToShow.map((notif) => (
        <ListItem key={notif.id}>
          <CustomNotification
            notification={notif}
            onClick={() => closeNotification(notif.id)}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default NotificationList;
